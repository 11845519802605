
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace('')
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as abuseReportskKXqaIypJHMeta } from "/home/admin/www/zaimki.pl/release/20250125181759/pages/admin/abuseReports.vue?macro=true";
import { default as _91id_93OW9E4sVZ3EMeta } from "/home/admin/www/zaimki.pl/release/20250125181759/pages/admin/audit-log/[username]/[id].vue?macro=true";
import { default as editorCTQ4vPxBVxMeta } from "/home/admin/www/zaimki.pl/release/20250125181759/pages/admin/blog/editor.vue?macro=true";
import { default as indexImQX4W0zFiMeta } from "/home/admin/www/zaimki.pl/release/20250125181759/pages/admin/index.vue?macro=true";
import { default as moderationeaq3mkMqs1Meta } from "/home/admin/www/zaimki.pl/release/20250125181759/pages/admin/moderation.vue?macro=true";
import { default as pendingBansYXjCcMthW5Meta } from "/home/admin/www/zaimki.pl/release/20250125181759/pages/admin/pendingBans.vue?macro=true";
import { default as profilesa31iqBLBD1Meta } from "/home/admin/www/zaimki.pl/release/20250125181759/pages/admin/profiles.vue?macro=true";
import { default as expensesX8Fe6sYLK1Meta } from "/home/admin/www/zaimki.pl/release/20250125181759/pages/admin/timesheets/expenses.vue?macro=true";
import { default as indexcuMVjotqKYMeta } from "/home/admin/www/zaimki.pl/release/20250125181759/pages/admin/timesheets/index.vue?macro=true";
import { default as overviewIS0qubwWKXMeta } from "/home/admin/www/zaimki.pl/release/20250125181759/pages/admin/timesheets/overview.vue?macro=true";
import { default as awaitingxoWuciItL4Meta } from "/home/admin/www/zaimki.pl/release/20250125181759/pages/admin/translations/awaiting.vue?macro=true";
import { default as missingY3d60n6VDQMeta } from "/home/admin/www/zaimki.pl/release/20250125181759/pages/admin/translations/missing.vue?macro=true";
import { default as usersv9jUb0uyUfMeta } from "/home/admin/www/zaimki.pl/release/20250125181759/pages/admin/users.vue?macro=true";
import { default as apigieOifQutgMeta } from "/home/admin/www/zaimki.pl/release/20250125181759/pages/api.vue?macro=true";
import { default as _91slug_93V8YgdoJfCfMeta } from "/home/admin/www/zaimki.pl/release/20250125181759/pages/blog/[slug].vue?macro=true";
import { default as indexzhPVRowUVjMeta } from "/home/admin/www/zaimki.pl/release/20250125181759/pages/blog/index.vue?macro=true";
import { default as _91_91year_93_932HtKGIByAZMeta } from "/home/admin/www/zaimki.pl/release/20250125181759/pages/calendar/[[year]].vue?macro=true";
import { default as _91year_93_45_91month_93_45_91day_936Z5rSxpkt4Meta } from "/home/admin/www/zaimki.pl/release/20250125181759/pages/calendar/[year]-[month]-[day].vue?macro=true";
import { default as adminryndPUbjmFMeta } from "/home/admin/www/zaimki.pl/release/20250125181759/pages/census/admin.vue?macro=true";
import { default as index8GbEjDVhUMMeta } from "/home/admin/www/zaimki.pl/release/20250125181759/pages/census/index.vue?macro=true";
import { default as contactXvACoPI5nuMeta } from "/home/admin/www/zaimki.pl/release/20250125181759/pages/contact.vue?macro=true";
import { default as designX3Q7aeo9yPMeta } from "/home/admin/www/zaimki.pl/release/20250125181759/pages/design.vue?macro=true";
import { default as englishxSeqb9djZNMeta } from "/home/admin/www/zaimki.pl/release/20250125181759/pages/english.vue?macro=true";
import { default as faqsemaTPZHqhMeta } from "/home/admin/www/zaimki.pl/release/20250125181759/pages/faq.vue?macro=true";
import { default as inclusiveNFzAZ7Mke7Meta } from "/home/admin/www/zaimki.pl/release/20250125181759/pages/inclusive.vue?macro=true";
import { default as index_45home78dCAmKbwSMeta } from "/home/admin/www/zaimki.pl/release/20250125181759/pages/index-home.vue?macro=true";
import { default as index_45localew4SotodgOjMeta } from "/home/admin/www/zaimki.pl/release/20250125181759/pages/index-locale.vue?macro=true";
import { default as langswitchBmA0V0ClEUMeta } from "/home/admin/www/zaimki.pl/release/20250125181759/pages/langswitch.vue?macro=true";
import { default as licensetBls6ligXlMeta } from "/home/admin/www/zaimki.pl/release/20250125181759/pages/license.vue?macro=true";
import { default as academicC2JXMUxVpiMeta } from "/home/admin/www/zaimki.pl/release/20250125181759/pages/links/academic.vue?macro=true";
import { default as indexcod7YAZcUFMeta } from "/home/admin/www/zaimki.pl/release/20250125181759/pages/links/index.vue?macro=true";
import { default as mediaJRbwbo79gyMeta } from "/home/admin/www/zaimki.pl/release/20250125181759/pages/links/media.vue?macro=true";
import { default as translinguisticsVWwyHLLv8tMeta } from "/home/admin/www/zaimki.pl/release/20250125181759/pages/links/translinguistics.vue?macro=true";
import { default as zinefa1zPjPno0Meta } from "/home/admin/www/zaimki.pl/release/20250125181759/pages/links/zine.vue?macro=true";
import { default as namesQdCFWcDk2MMeta } from "/home/admin/www/zaimki.pl/release/20250125181759/pages/names.vue?macro=true";
import { default as indexSuPYFWFMfOMeta } from "/home/admin/www/zaimki.pl/release/20250125181759/pages/nouns/index.vue?macro=true";
import { default as templatesO9f0wPQ8nbMeta } from "/home/admin/www/zaimki.pl/release/20250125181759/pages/nouns/templates.vue?macro=true";
import { default as peoplei2vDP8NsCgMeta } from "/home/admin/www/zaimki.pl/release/20250125181759/pages/people.vue?macro=true";
import { default as privacyA16Aq0zI6KMeta } from "/home/admin/www/zaimki.pl/release/20250125181759/pages/privacy.vue?macro=true";
import { default as _91username_93I6oEYEvV5UMeta } from "/home/admin/www/zaimki.pl/release/20250125181759/pages/profile/[username].vue?macro=true";
import { default as card_45_91username_93WvweQVZb4iMeta } from "/home/admin/www/zaimki.pl/release/20250125181759/pages/profile/card-[username].vue?macro=true";
import { default as editor7b14tQlnfaMeta } from "/home/admin/www/zaimki.pl/release/20250125181759/pages/profile/editor.vue?macro=true";
import { default as anynHn4jFYjugMeta } from "/home/admin/www/zaimki.pl/release/20250125181759/pages/pronouns/any.vue?macro=true";
import { default as ask45AJuz0Zf8Meta } from "/home/admin/www/zaimki.pl/release/20250125181759/pages/pronouns/ask.vue?macro=true";
import { default as avoidingWlP4J8ZsvyMeta } from "/home/admin/www/zaimki.pl/release/20250125181759/pages/pronouns/avoiding.vue?macro=true";
import { default as indexr4uTJa0gDPMeta } from "/home/admin/www/zaimki.pl/release/20250125181759/pages/pronouns/index.vue?macro=true";
import { default as mirrorRjZ1eYjGj6Meta } from "/home/admin/www/zaimki.pl/release/20250125181759/pages/pronouns/mirror.vue?macro=true";
import { default as pronounuflEcFDaLdMeta } from "/home/admin/www/zaimki.pl/release/20250125181759/pages/pronouns/pronoun.vue?macro=true";
import { default as sourcesKaQYSTIqKOMeta } from "/home/admin/www/zaimki.pl/release/20250125181759/pages/sources.vue?macro=true";
import { default as team0MjlTyilBRMeta } from "/home/admin/www/zaimki.pl/release/20250125181759/pages/team.vue?macro=true";
import { default as terminology8HkjtCzRgkMeta } from "/home/admin/www/zaimki.pl/release/20250125181759/pages/terminology.vue?macro=true";
import { default as termscvN8H1wBV7Meta } from "/home/admin/www/zaimki.pl/release/20250125181759/pages/terms.vue?macro=true";
import { default as userHAZWzIrom9Meta } from "/home/admin/www/zaimki.pl/release/20250125181759/pages/user.vue?macro=true";
import { default as workshops5jLrgWxYExMeta } from "/home/admin/www/zaimki.pl/release/20250125181759/pages/workshops.vue?macro=true";
import { default as neutratywy1pA2aUI8UMMeta } from "~/data/nouns/neutratywy.vue?macro=true";
import { default as dukatywyefwx6B0yWaMeta } from "~/data/nouns/dukatywy.vue?macro=true";
import { default as osobatywyJt6U0sHeFZMeta } from "~/data/nouns/osobatywy.vue?macro=true";
import { default as iksatywy4G8TfyCT5SMeta } from "~/data/nouns/iksatywy.vue?macro=true";
export default [
  {
    name: "admin-abuseReports",
    path: "/admin/abuse-reports",
    meta: abuseReportskKXqaIypJHMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20250125181759/pages/admin/abuseReports.vue")
  },
  {
    name: "admin-audit-log-username-id",
    path: "/admin/audit-log/:username()/:id()",
    component: () => import("/home/admin/www/zaimki.pl/release/20250125181759/pages/admin/audit-log/[username]/[id].vue")
  },
  {
    name: "admin-blog-editor",
    path: "/admin/blog/editor",
    component: () => import("/home/admin/www/zaimki.pl/release/20250125181759/pages/admin/blog/editor.vue")
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/home/admin/www/zaimki.pl/release/20250125181759/pages/admin/index.vue")
  },
  {
    name: "admin-moderation",
    path: "/admin/moderation",
    component: () => import("/home/admin/www/zaimki.pl/release/20250125181759/pages/admin/moderation.vue")
  },
  {
    name: "admin-pendingBans",
    path: "/admin/pending-bans",
    meta: pendingBansYXjCcMthW5Meta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20250125181759/pages/admin/pendingBans.vue")
  },
  {
    name: "admin-profiles",
    path: "/admin/profiles",
    component: () => import("/home/admin/www/zaimki.pl/release/20250125181759/pages/admin/profiles.vue")
  },
  {
    name: "admin-timesheets-expenses",
    path: "/admin/timesheets/expenses",
    component: () => import("/home/admin/www/zaimki.pl/release/20250125181759/pages/admin/timesheets/expenses.vue")
  },
  {
    name: "admin-timesheets",
    path: "/admin/timesheets",
    component: () => import("/home/admin/www/zaimki.pl/release/20250125181759/pages/admin/timesheets/index.vue")
  },
  {
    name: "admin-timesheets-overview",
    path: "/admin/timesheets/overview",
    component: () => import("/home/admin/www/zaimki.pl/release/20250125181759/pages/admin/timesheets/overview.vue")
  },
  {
    name: "admin-translations-awaiting",
    path: "/admin/translations/awaiting",
    component: () => import("/home/admin/www/zaimki.pl/release/20250125181759/pages/admin/translations/awaiting.vue")
  },
  {
    name: "admin-translations-missing",
    path: "/admin/translations/missing",
    component: () => import("/home/admin/www/zaimki.pl/release/20250125181759/pages/admin/translations/missing.vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    component: () => import("/home/admin/www/zaimki.pl/release/20250125181759/pages/admin/users.vue")
  },
  {
    name: "api",
    path: "/api",
    meta: apigieOifQutgMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20250125181759/pages/api.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    meta: _91slug_93V8YgdoJfCfMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20250125181759/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexzhPVRowUVjMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20250125181759/pages/blog/index.vue")
  },
  {
    name: "calendar",
    path: "/calendar/:year?",
    meta: _91_91year_93_932HtKGIByAZMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20250125181759/pages/calendar/[[year]].vue")
  },
  {
    name: "calendarDay",
    path: "/calendar/:year()-:month()-:day()",
    meta: _91year_93_45_91month_93_45_91day_936Z5rSxpkt4Meta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20250125181759/pages/calendar/[year]-[month]-[day].vue")
  },
  {
    name: "census-admin",
    path: "/census/admin",
    meta: adminryndPUbjmFMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20250125181759/pages/census/admin.vue")
  },
  {
    name: "census",
    path: "/census",
    meta: index8GbEjDVhUMMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20250125181759/pages/census/index.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: contactXvACoPI5nuMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20250125181759/pages/contact.vue")
  },
  {
    name: "design",
    path: "/design",
    component: () => import("/home/admin/www/zaimki.pl/release/20250125181759/pages/design.vue")
  },
  {
    name: "english",
    path: "/english",
    meta: englishxSeqb9djZNMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20250125181759/pages/english.vue")
  },
  {
    name: "faq",
    path: "/faq",
    meta: faqsemaTPZHqhMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20250125181759/pages/faq.vue")
  },
  {
    name: "inclusive",
    path: "/inclusive",
    meta: inclusiveNFzAZ7Mke7Meta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20250125181759/pages/inclusive.vue")
  },
  {
    name: "index-home",
    path: "/index-home",
    meta: index_45home78dCAmKbwSMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20250125181759/pages/index-home.vue")
  },
  {
    name: "index",
    path: "/index-locale",
    meta: index_45localew4SotodgOjMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20250125181759/pages/index-locale.vue")
  },
  {
    name: "langswitch",
    path: "/langswitch",
    meta: langswitchBmA0V0ClEUMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20250125181759/pages/langswitch.vue")
  },
  {
    name: "license",
    path: "/license",
    component: () => import("/home/admin/www/zaimki.pl/release/20250125181759/pages/license.vue")
  },
  {
    name: "links-academic",
    path: "/links/academic",
    meta: academicC2JXMUxVpiMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20250125181759/pages/links/academic.vue")
  },
  {
    name: "links",
    path: "/links",
    meta: indexcod7YAZcUFMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20250125181759/pages/links/index.vue")
  },
  {
    name: "links-media",
    path: "/links/media",
    meta: mediaJRbwbo79gyMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20250125181759/pages/links/media.vue")
  },
  {
    name: "links-translinguistics",
    path: "/links/translinguistics",
    meta: translinguisticsVWwyHLLv8tMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20250125181759/pages/links/translinguistics.vue")
  },
  {
    name: "links-zine",
    path: "/links/zine",
    meta: zinefa1zPjPno0Meta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20250125181759/pages/links/zine.vue")
  },
  {
    name: "names",
    path: "/names",
    meta: namesQdCFWcDk2MMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20250125181759/pages/names.vue")
  },
  {
    name: "nouns",
    path: "/nouns",
    meta: indexSuPYFWFMfOMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20250125181759/pages/nouns/index.vue")
  },
  {
    name: "nouns-templates",
    path: "/nouns/templates",
    meta: templatesO9f0wPQ8nbMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20250125181759/pages/nouns/templates.vue")
  },
  {
    name: "people",
    path: "/people",
    meta: peoplei2vDP8NsCgMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20250125181759/pages/people.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacyA16Aq0zI6KMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20250125181759/pages/privacy.vue")
  },
  {
    name: "profile-username",
    path: "/profile/:username()",
    meta: _91username_93I6oEYEvV5UMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20250125181759/pages/profile/[username].vue")
  },
  {
    name: "profile-card-username",
    path: "/profile/card-:username()",
    meta: card_45_91username_93WvweQVZb4iMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20250125181759/pages/profile/card-[username].vue")
  },
  {
    name: "profile-editor",
    path: "/profile/editor",
    meta: editor7b14tQlnfaMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20250125181759/pages/profile/editor.vue")
  },
  {
    name: "pronouns-any",
    path: "/pronouns/any",
    meta: anynHn4jFYjugMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20250125181759/pages/pronouns/any.vue")
  },
  {
    name: "pronouns-ask",
    path: "/pronouns/ask",
    meta: ask45AJuz0Zf8Meta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20250125181759/pages/pronouns/ask.vue")
  },
  {
    name: "pronouns-avoiding",
    path: "/pronouns/avoiding",
    meta: avoidingWlP4J8ZsvyMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20250125181759/pages/pronouns/avoiding.vue")
  },
  {
    name: "pronouns",
    path: "/pronouns",
    meta: indexr4uTJa0gDPMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20250125181759/pages/pronouns/index.vue")
  },
  {
    name: "pronouns-mirror",
    path: "/pronouns/mirror",
    meta: mirrorRjZ1eYjGj6Meta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20250125181759/pages/pronouns/mirror.vue")
  },
  {
    name: "all",
    path: "/:path(.*)",
    meta: pronounuflEcFDaLdMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20250125181759/pages/pronouns/pronoun.vue")
  },
  {
    name: "sources",
    path: "/sources",
    meta: sourcesKaQYSTIqKOMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20250125181759/pages/sources.vue")
  },
  {
    name: "team",
    path: "/team",
    meta: team0MjlTyilBRMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20250125181759/pages/team.vue")
  },
  {
    name: "terminology",
    path: "/terminology",
    meta: terminology8HkjtCzRgkMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20250125181759/pages/terminology.vue")
  },
  {
    name: "terms",
    path: "/terms",
    meta: termscvN8H1wBV7Meta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20250125181759/pages/terms.vue")
  },
  {
    name: "user",
    path: "/user",
    meta: userHAZWzIrom9Meta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20250125181759/pages/user.vue")
  },
  {
    name: "workshops",
    path: "/workshops",
    meta: workshops5jLrgWxYExMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20250125181759/pages/workshops.vue")
  },
  {
    name: "nouns-neutratywy",
    path: "/neutratywy",
    component: () => import("~/data/nouns/neutratywy.vue")
  },
  {
    name: "nouns-dukatywy",
    path: "/dukatywy",
    component: () => import("~/data/nouns/dukatywy.vue")
  },
  {
    name: "nouns-osobatywy",
    path: "/osobatywy",
    component: () => import("~/data/nouns/osobatywy.vue")
  },
  {
    name: "nouns-iksatywy",
    path: "/iksatywy",
    component: () => import("~/data/nouns/iksatywy.vue")
  }
]