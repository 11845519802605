<script setup lang="ts" generic="T extends Partial<Noun> & MinimalNoun & { id: string }">
import type { MinimalNoun, Noun } from '~/src/classes.ts';
import { availableGenders } from '~/src/nouns.ts';

defineProps<{
    nouns: T[];
    marked?: (element: T) => boolean;
}>();

const config = useConfig();

const table = useTemplateRef('table');
defineExpose({
    reset() {
        table.value?.reset();
    },
    focus() {
        table.value?.focus();
    },
});
</script>

<template>
    <Table ref="table" :data="nouns" :marked fixed>
        <template #header>
            <div v-for="gender in availableGenders(config)" :key="gender" class="d-none d-md-block bold">
                <NounsGenderLabel :gender="gender" />
            </div>
        </template>
        <template #row="{ el: noun }">
            <NounsTableEntry :noun>
                <template #buttons>
                    <ul class="list-unstyled list-btn-concise">
                        <slot name="buttons" :noun="noun"></slot>
                    </ul>
                </template>
            </NounsTableEntry>
        </template>

        <template #empty>
            <Icon v="search" />
            <T>nouns.empty</T>
        </template>
    </Table>
</template>

<style scoped lang="scss">
@import "assets/variables";

:deep(.row-header) {
  grid-template-columns: 1fr 1fr 1fr 3em;

  .nouns-table-nonbinary & {
    grid-template-columns: 1fr 1fr 1fr 1fr 3em;
  }
}

:deep(.row-content) {
  grid:
            "mascLabel masc mascPl"
            "femLabel fem femPl"
            "neutrLabel neutr neutrPl"
            "nbLabel nb nbPl"
            "sources sources sources"
            "buttons buttons buttons"
            / auto 1fr 1fr;

  @include media-breakpoint-up('md', $grid-breakpoints) {
    grid:
                "masc fem neutr buttons"
                "mascPl femPl neutrPl buttons"
                "sources sources sources buttons"
                / 1fr 1fr 1fr 3em;

    .nouns-table-nonbinary & {
      grid:
                  "masc fem neutr nb buttons"
                  "mascPl femPl neutrPl nbPl buttons"
                  "sources sources sources sources buttons"
                  / 1fr 1fr 1fr 1fr 3em;
    }
  }
}
</style>
