<script setup lang="ts">
import { type Gender, iconNamesByGender, longIdentifierByGender } from '~/src/nouns.ts';

const props = defineProps<{
    gender: Gender;
    concise?: boolean;
}>();

const iconName = computed((): string => {
    return iconNamesByGender[props.gender];
});
const longIdentifier = computed((): string => {
    return longIdentifierByGender[props.gender];
});
</script>

<template>
    <Tooltip v-if="concise" :text="$t(`nouns.${longIdentifier}`)">
        <Icon :v="iconName" />
    </Tooltip>
    <div v-else class="text-nowrap">
        <Icon :v="iconName" />
        <span><T>nouns.{{ longIdentifier }}</T></span>
    </div>
</template>
