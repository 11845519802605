<script setup lang="ts">
import type { MinimalNoun } from '~/src/classes.ts';
import type { Gender } from '~/src/nouns.ts';

defineProps<{
    noun: MinimalNoun;
    gender: Gender;
    plural?: boolean;
}>();

const config = useConfig();
</script>

<template>
    <ul :class="[plural ? 'list-plural' : 'list-singular', 'mb-0']">
        <li v-for="(w, i) in noun[`${gender}${plural ? 'Pl' : ''}`]" :key="i">
            <Abbreviation v-slot="{ word }" :v="w">
                <NounsDeclension
                    v-if="gender === 'neutr' && config.nouns.declension"
                    :word="word"
                    :plural="plural"
                    tooltip
                />
                <Spelling v-else :text="word" />
            </Abbreviation>
        </li>
    </ul>
</template>
