<script setup lang="ts">
import type { MinimalNoun } from '~/src/classes.ts';
import { nounTemplates } from '~/src/data.ts';
import { gendersWithNumerus } from '~/src/nouns.ts';

const props = withDefaults(defineProps<{
    templateBase?: string;
    filter?: string;
}>(), {
    templateBase: '',
    filter: '',
});

const templates = computed((): (MinimalNoun & { id: string })[] => {
    return nounTemplates.filter((template) => {
        for (const field of gendersWithNumerus) {
            for (const value of template[field]) {
                if (value.toLowerCase().includes(props.filter)) {
                    return true;
                }
            }
        }
    }).map((template, i) => {
        return {
            id: `template-${i}`,
            ...template.fill(props.templateBase || '-'),
        };
    });
});
</script>

<template>
    <NounsTable :nouns="templates">
        <template #buttons="{ noun }">
            <ul class="list-unstyled list-btn-concise">
                <slot name="buttons" :template="noun"></slot>
            </ul>
        </template>
    </NounsTable>
</template>
