<script setup lang="ts">
import type { Config } from '~/locale/config.ts';
import type { Noun, MinimalNoun } from '~/src/classes.ts';
import { abbreviations } from '~/src/data.ts';
import { availableGenders } from '~/src/nouns.ts';
import type { Gender } from '~/src/nouns.ts';

const emptyForm = (config: Config): MinimalNoun => {
    return {
        masc: [''],
        fem: [''],
        neutr: [''],
        nb: [''],
        mascPl: config.nouns.pluralsRequired ? [''] : [],
        femPl: config.nouns.pluralsRequired ? [''] : [],
        neutrPl: config.nouns.pluralsRequired ? [''] : [],
        nbPl: config.nouns.pluralsRequired ? [''] : [],
        categories: [],
        sources: [],
        base: null,
    };
};

const emit = defineEmits<{
    submit: [];
}>();

const { $translator: translator } = useNuxtApp();
const config = useConfig();

const section = useTemplateRef<HTMLElement>('section');
const templateFilterInput = useTemplateRef<HTMLInputElement>('templateFilterInput');

const form = ref(emptyForm(config));

const submitting = ref(false);
const afterSubmit = ref(false);

const templateBase = ref('');
const templateFilter = ref('');
const templateVisible = ref(false);

const canRemoveWord = (gender: Gender, plural: boolean): boolean => {
    return availableGenders(config).filter((otherGender) => {
        return otherGender !== gender && form.value[plural ? `${otherGender}Pl` as const : otherGender].length > 0;
    }).length > 1 || form.value[plural ? `${gender}Pl` as const : gender].length > 1;
};

const dialogue = useDialogue();
const applyTemplate = async (template: MinimalNoun): Promise<void> => {
    if (JSON.stringify(form.value) !== JSON.stringify(emptyForm(config))) {
        await dialogue.confirm(translator.translate('nouns.template.overwrite'));
    }
    form.value = template;
    templateVisible.value = false;
    await nextTick();
    section.value?.scrollIntoView();
};
const submit = async () => {
    submitting.value = true;
    try {
        await dialogue.postWithAlertOnError('/api/nouns/submit', form.value);

        afterSubmit.value = true;
        form.value = emptyForm(config);
        templateVisible.value = false;
        templateBase.value = '';
        focus(false);
        emit('submit');
    } finally {
        submitting.value = false;
    }
};
const edit = (word: Noun): void => {
    form.value = {
        masc: word.masc,
        fem: word.fem,
        neutr: word.neutr,
        nb: word.nb,
        mascPl: word.mascPl,
        femPl: word.femPl,
        neutrPl: word.neutrPl,
        nbPl: word.nbPl,
        categories: word.categories,
        sources: word.sources,
        base: word.id,
    };
    focus();
};
const focus = (editable = true): void => {
    if (editable) {
        afterSubmit.value = false;
    }
    section.value?.scrollIntoView();
};

defineExpose({ edit, focus });

const { data: sourcesKeys } = await useFetch('/api/sources/keys', { lazy: true, default: () => [] });
</script>

<template>
    <section v-if="config.nouns.enabled && $user()" ref="section" class="px-md-3 scroll-mt-7">
        <div v-if="afterSubmit" class="alert alert-success text-center">
            <p>
                <T>nouns.submit.thanks</T>
            </p>
            <p>
                <button class="btn btn-success" @click="focus()">
                    <Icon v="plus" />
                    <T>nouns.submit.another</T>
                </button>
            </p>
        </div>
        <form v-else @submit.prevent="submit">
            <div class="row">
                <div v-if="config.nouns.plurals" class="col-12 col-md-2 text-nowrap mt-md-4">
                    <label><strong>⋅ <T>nouns.singular</T></strong></label>
                </div>
                <div v-for="gender in availableGenders(config)" :key="gender" class="col-6 col-sm">
                    <label><strong><NounsGenderLabel :gender="gender" /></strong></label>
                    <ListInput
                        v-model="form[gender]"
                        :maxlength="36"
                        :minitems="canRemoveWord(gender, false) ? 0 : 1"
                    />
                </div>
            </div>
            <div v-if="config.nouns.plurals" class="row">
                <div class="col-12 col-md-2 text-nowrap">
                    <label><strong>⁖ <T>nouns.plural</T></strong></label>
                </div>
                <div v-for="gender in availableGenders(config)" :key="gender" class="col-6 col-sm">
                    <label class="d-md-none"><strong><NounsGenderLabel :gender="gender" /></strong></label>
                    <ListInput
                        v-model="form[`${gender}Pl`]"
                        :maxlength="36"
                        :minitems="!config.nouns.pluralsRequired || canRemoveWord(gender, true) ? 0 : 1"
                    />
                </div>
            </div>
            <CategoriesSelector
                v-model="form.categories"
                :label="$t('nouns.categories')"
                :categories="config.nouns.categories"
            />
            <div v-if="$isGranted('sources')" class="form-group">
                <label><strong><T>sources.referenced</T><T>quotation.colon</T></strong></label>
                <ListInput v-model="form.sources">
                    <template #default="{ val: key, update }">
                        <AutocompleteSelect
                            :model-value="key"
                            :options="sourcesKeys"
                            @update:model-value="update"
                        />
                    </template>
                </ListInput>
            </div>
            <div v-if="form.base" class="alert alert-info">
                <Icon v="info-circle" />
                <T>nouns.editing</T>
                <button class="btn btn-sm float-end" @click="form.base = null">
                    <Icon v="times" />
                </button>
            </div>

            <template v-if="config.nouns.templates?.enabled">
                <a v-if="!templateVisible" href="#" class="btn btn-outline-primary w-100 mb-3" @click.prevent="templateVisible = true">
                    <Icon v="copy" />
                    <T>nouns.template.header</T>
                </a>
                <div v-else class="card mb-3">
                    <a href="#" class="card-header" @click.prevent="templateVisible = false">
                        <Icon v="copy" />
                        <T>nouns.template.header</T>
                    </a>
                    <div class="card-body">
                        <div class="input-group mb-3 bg-white">
                            <span class="input-group-text">
                                <Icon v="scroll-old" />
                            </span>
                            <input
                                v-model="templateBase"
                                class="form-control form-control-sm border-primary"
                                autofocus
                                :placeholder="$t('nouns.template.root')"
                            >
                            <template v-if="config.nouns.templates.filter">
                                <span class="input-group-text">
                                    <Icon v="filter" />
                                </span>
                                <input
                                    ref="templateFilterInput"
                                    v-model="templateFilter"
                                    class="form-control form-control-sm border-primary"
                                    :placeholder="$t('crud.filterLong')"
                                >
                                <button
                                    v-if="templateFilter"
                                    class="btn btn-sm btn-outline-danger"
                                    @click="templateFilter = ''; templateFilterInput?.focus()"
                                >
                                    <Icon v="times" />
                                </button>
                            </template>
                        </div>
                        <NounsTemplatesTable :template-base="templateBase" :filter="templateFilter">
                            <template #buttons="{ template }">
                                <li>
                                    <button
                                        type="button"
                                        class="btn btn-concise btn-outline-primary btn-sm"
                                        :disabled="!templateBase"
                                        @click="applyTemplate(template)"
                                    >
                                        <Icon v="copy" />
                                        <span class="btn-label"><T>nouns.template.apply</T></span>
                                    </button>
                                </li>
                            </template>
                        </NounsTemplatesTable>
                    </div>
                </div>
            </template>

            <button class="btn btn-primary w-100" :disabled="submitting">
                <template v-if="submitting">
                    <Icon v="circle-notch fa-spin" />
                </template>
                <template v-else>
                    <Icon v="plus" />
                    <T>nouns.submit.actionLong</T>
                </template>
            </button>
            <p class="small text-muted mt-1">
                <T>nouns.submit.moderation</T>
            </p>
            <ul v-if="Object.keys(abbreviations).length > 0" class="small text-muted">
                <li v-for="(meaning, abbr) in abbreviations">
                    {{ abbr }} – {{ meaning }}
                </li>
            </ul>
        </form>
    </section>
    <section v-else ref="section" class="text-center">
        <div class="alert alert-info">
            <T>crud.loginRequired</T>
        </div>
    </section>
</template>
