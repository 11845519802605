<script setup lang="ts">
import type NounsSubmitForm from '~/components/nouns/NounsSubmitForm.vue';
import { Noun } from '~/src/classes.ts';
import type { NounRaw, Filter } from '~/src/classes.ts';
import { buildDict } from '~/src/helpers.ts';

const props = defineProps<{
    load?: boolean;
}>();

const { $translator: translator } = useNuxtApp();
const config = useConfig();
const filter = useFilterWithCategory();

const dictionarytable = useTemplateRef('dictionarytable');
watch(filter, () => {
    if (dictionarytable.value) {
        dictionarytable.value.reset();
        dictionarytable.value.focus();
    }
});
const form = useTemplateRef<InstanceType<typeof NounsSubmitForm>>('form');

const nounsAsyncData = useAsyncData(async () => {
    const nounsRaw = await $fetch<NounRaw[]>('/api/nouns');

    return buildDict(function* () {
        const sorted = nounsRaw.sort((a, b) => {
            if (a.approved && !b.approved) {
                return 1;
            }
            if (!a.approved && b.approved) {
                return -1;
            }
            return a.masc.toLowerCase().localeCompare(b.masc.toLowerCase(), config.locale);
        });
        for (const w of sorted) {
            yield [w.id, new Noun(config, w)];
        }
    });
}, {
    immediate: false,
});

const loadNouns = async (): Promise<void> => {
    if (nounsAsyncData.data.value === null) {
        await nounsAsyncData.execute();
    }
};
onMounted(async () => {
    if (props.load) {
        await loadNouns();
    }
});
const reloadNouns = async (): Promise<void> => {
    await nounsAsyncData.execute();
    form.value?.focus(false);
};

const nouns = computed(() => {
    if (nounsAsyncData.status.value !== 'success') {
        return {};
    }
    return nounsAsyncData.data.value!;
});
const visibleNouns = computed(() => {
    return Object.values(nouns.value).filter((n) => n.matches(filter.value));
});

const moderationFilters = computed((): Filter['moderation'][] => {
    const categories: Filter['moderation'][] = ['unapproved'];
    if (config.nouns.categories && config.nouns.categories.length > 0) {
        categories.push('no category');
    }
    return categories;
});

const dialogue = useDialogue();
const edit = (noun: Noun): void => {
    form.value?.edit(noun);
};
const approve = async (noun: Noun): Promise<void> => {
    await dialogue.postWithAlertOnError(`/api/nouns/approve/${noun.id}`);
    if (noun.base) {
        delete nouns.value[noun.base];
    }
    noun.approved = true;
    noun.base = null;
};
const hide = async (noun: Noun): Promise<void> => {
    await dialogue.postWithAlertOnError(`/api/nouns/hide/${noun.id}`);
    noun.approved = false;
};
const remove = async (noun: Noun): Promise<void> => {
    await dialogue.confirm(translator.translate('crud.removeConfirm'), 'danger');

    await dialogue.postWithAlertOnError(`/api/nouns/remove/${noun.id}`);
    delete nouns.value[noun.id];
};

defineExpose({ loadNouns });
</script>

<template>
    <Loading :value="nounsAsyncData.data.value">
        <ModerationSection
            v-model="filter.moderation"
            kind="nouns"
            :moderation-filters
            :entries="Object.values(nouns)"
        />

        <FilterBar
            v-model="filter.text"
            v-model:category="filter.category"
            :categories="config.nouns.categories"
            submit-button
            @submit-clicked="form?.focus()"
        />

        <NounsTable
            ref="dictionarytable"
            :class="[config.nouns.nonbinary ? 'nouns-table-nonbinary' : '']"
            :nouns="visibleNouns"
            :marked="(el) => !el.approved"
        >
            <template #buttons="{ noun }">
                <ul class="d-flex flex-wrap flex-md-column list-unstyled list-btn-concise mb-0">
                    <template v-if="$isGranted('nouns')">
                        <li v-if="noun.author" class="small">
                            <nuxt-link
                                :to="`/@${noun.author}`"
                                class="btn btn-concise btn-outline-dark btn-sm m-1"
                            >
                                <Icon v="user" />
                                <span class="btn-label">
                                    <T>crud.author</T><T>quotation.colon</T>
                                    @{{ noun.author }}
                                </span>
                            </nuxt-link>
                        </li>
                        <li v-if="!noun.approved">
                            <button class="btn btn-concise btn-success btn-sm m-1" @click="approve(noun)">
                                <Icon v="check" />
                                <span class="btn-label"><T>crud.approve</T></span>
                            </button>
                        </li>
                        <li v-else @click="hide(noun)">
                            <button class="btn btn-concise btn-outline-secondary btn-sm m-1">
                                <Icon v="times" />
                                <span class="btn-label"><T>crud.hide</T></span>
                            </button>
                        </li>
                        <li>
                            <button class="btn btn-concise btn-outline-danger btn-sm m-1" @click="remove(noun)">
                                <Icon v="trash" />
                                <span class="btn-label"><T>crud.remove</T></span>
                            </button>
                        </li>
                    </template>
                    <li>
                        <button class="btn btn-concise btn-outline-primary btn-sm m-1" @click="edit(noun)">
                            <Icon v="pen" />
                            <span class="btn-label">
                                <T v-if="$isGranted('nouns')">crud.edit</T>
                                <T v-else>nouns.edit</T>
                            </span>
                        </button>
                    </li>
                    <li>
                        <a
                            :href="`/api/nouns/${noun.id}.png`"
                            target="_blank"
                            rel="noopener"
                            class="btn btn-concise btn-outline-primary btn-sm m-1"
                        >
                            <Icon v="image" />
                            <span class="btn-label">
                                <T>nouns.image</T>
                            </span>
                        </a>
                    </li>
                </ul>
            </template>
        </NounsTable>

        <AdPlaceholder :phkey="['content-1', 'content-mobile-1']" />

        <template v-if="config.nouns.submit">
            <Separator icon="plus" />

            <NounsSubmitForm ref="form" @submit="reloadNouns()" />
        </template>
    </Loading>
</template>
